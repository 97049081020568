import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import WorkStyled from '@/components/styled/WorkStyled';
import { WorkPageProps } from '@/types';
import Helper from '@/utils/helpers';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';

const Work: React.FC<WorkPageProps> = ({ data }) => {
  const workCases = data.allContentfulCase.nodes;

  const renderCases = () =>
    workCases.map((_case) => (
      <Reveal className="case card" key={_case.slug}>
        <Link
          className="details"
          to={`/work/${_case.slug}`}
          arial-label={`${_case.projectName}`}
        >
          <div className="image-container">
            {_case.featuredImageMobile && (
              <>
                <GatsbyImage
                  className="i-amphtml-fill-content i-amphtml-replaced-content"
                  image={getImage(_case.featuredImageMobile.gatsbyImageData)}
                  alt=""
                />
              </>
            )}
            <div className="case-categories">
              {_case.category &&
                _case.category.map((_category) => (
                  <div
                    className="category-item"
                    key={Helper.camelToSnakeCase(_category)}
                  >
                    {_category}
                  </div>
                ))}
            </div>
          </div>
          <div className="case-label">
            <h2>{_case.headline}</h2>
          </div>
        </Link>

        <div className="action">
          <Link
            className="link"
            to={`/work/${_case.slug}`}
            aria-label={`${_case.projectName}`}
          >
            Case {_case.projectName}
          </Link>
        </div>
      </Reveal>
    ));

  return (
    <WorkStyled>
      {usePageMeta(data.contentfulWork?.meta)}
      <div className="content cases-page">
        <div className="container">
          <Reveal className="page-title h1-title">
            <h1>Work</h1>
          </Reveal>
        </div>

        <div className="cases-container container">
          <div className="cases">{renderCases()}</div>
        </div>
      </div>
    </WorkStyled>
  );
};

export const query = graphql`
  query WorkPageQuery {
    contentfulWork {
      headline {
        raw
      }
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
    allContentfulCase(sort: { fields: order }) {
      nodes {
        slug
        headline
        projectName
        featuredImageAlt
        featuredImageMobile {
          gatsbyImageData
        }
        category
      }
      group(field: category) {
        field
        fieldValue
      }
    }
  }
`;

export default Work;
